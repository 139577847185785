import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";


export default function RegPayment() {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const cart = useSelector((state) => state.cart);

  const amount = cart.cartTotalAmount;
  const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    payment_options: "card,banktransfer,ussd",
    redirect_url: `https://titaa.net/product-payment-success?amount=${amount}`,
    customer: {
      email: user.email,
      phone_number: user.phone_number,
      username: user.username,
      name: user.fullname,
      cartItem: cart.cartItems,
      totalQuantity: cart.cartTotalQuantity,
      cartTotalAmount: cart.cartTotalAmount,
    },
    customizations: {
      title: "Titaa Multipurpose Soap",
      description: "Product Payment",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };
  const fwConfig = {
    ...config,
    text: "Pay Now!",
    callback: async () => {      
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {
      navigate('/')
    },
  };

  return (
    <section className="bg-white mx-auto h-screen">
      <div className="flex justify-center p-20">
        <div className="text-center ">
          <h1>Product Payment</h1>
          <FlutterWaveButton
            className="bg-orange-400 text-black text-bold p-2 "
            {...fwConfig}
          />
        </div>
      </div>
    </section>
  );
}
