import React, { useEffect,  } from 'react'
import { Link, } from 'react-router-dom'
import {  useUserPaymentMutation, } from "../services/paymentApi"


export default function ProductPaymentSuccessPage() {
  
    const [
        productPayment,
        // {
        //   data,
        //   isSuccess,
        //   isInitError,
        //   error,
        // },
      ] = useUserPaymentMutation();

  const params = window.location.search;
  const status = new URLSearchParams(params).get("status")
  const transaction_id = new URLSearchParams(params).get("transaction_id")
  const anount = new URLSearchParams(params).get("amount")

  const expectedAmount = parseInt(anount)

    useEffect(() => {
  
      if (status === 'completed') {
        try {
          async function paymentRedirect(){
            await productPayment({
                transaction_id, expectedAmount
              });
        }
        paymentRedirect()
        } catch (error) {
          console.log(error);
        }
      }
    }, [params,expectedAmount,productPayment,status,transaction_id]);
  return (
    <div >
      <h1>Your payment has been successfully processed</h1>
      <p>Your ticket No. is: {transaction_id} and { expectedAmount} </p>
      <Link to={'/dashboard'}>Back to dashboard</Link>
    </div>
  )
}
