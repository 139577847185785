import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import { useUserLoginMutation } from "../services/authsApi";
import { useDispatch } from "react-redux";
import { setUser } from "../features/authSlice";
import { MoonLoader } from "react-spinners";

export default function Auth() {
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  const [
    userLogin,
    { data: loginData, isLoading: loginLoading, isSuccess: isLoginSuccess },
  ] = useUserLoginMutation();
  const dispatch = useDispatch();

  const { email, password } = formValue;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormValue((formValue) => ({
      ...formValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (formValue) {
      if (!email && password) {
        toast.error("Wrong credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const userData = {
          email,
          password,
        };
        await userLogin(userData);

      }
    }
  };
  useEffect(() => {
    if (isLoginSuccess) {
      toast.success(`You have logged in successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      dispatch(
        setUser({
          fullname: loginData.data.fullname,
          email: loginData.data.email,
          phone_number: loginData.data.phone_number,
          username: loginData.data.username,
          loginToken: loginData.data.token,
        })
      );

      navigate("/dashboard");
    }
  }, [isLoginSuccess, loginLoading, dispatch]);


  return (
    <section
      className="hero min-h-screen bg-bg-off-white"
      style={{ color: "var(--color-black)" }}
    >
      {
loginLoading ? <MoonLoader color="#36d7b7" /> : 
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:p-10 lg:text-left">
          <h1
            className="text-5xl font-bold"
            style={{ color: "var(--color-purple)" }}
          >
            <>Login Now</>
          </h1>
          <p className="py-6">
          Login, Buy product, Refer and Earn Bonus with Exciting gifts Offers ...
          </p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm ">
          <div className="p-2 shadow-xl">
            <h1>
              <p className="pb-2">Please enter Login details</p>
            </h1>
            <form className="form-div" onSubmit={handleLogin}>
              <div className="">
                <label className="input-group pb-2">
                  <span className="w-1/4 pl-2 bg-purple-300 ">Email</span>
                  <input
                    type="email"
                    placeholder="info@titaa.net"
                    className="w-3/4 input input-bordered bg-purple-200"
                    required
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </label>
                <label className="input-group pb-2">
                  <span className="w-1/4 pl-2 bg-purple-300">Pasword</span>
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <button
                className="btn btn-active bg-purple-900 hover:bg-purple-300 hover:text-black"
                type="submit"
              >
                Login
              </button>
              <div className="">
                <h5>
                  Don't have an account with titaa Soap?</h5>
                  <div className="flex justify-between">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    <span className="">
                      {" "}
                      <Link> Register </Link>
                    </span>
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      navigate("/forgotpassword");
                    }}
                  >
                    {" "}
                    <span className="">
                      {" "}
                      <Link to="/forgotpassword"> Forgort Password</Link>
                    </span>
                  </button>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      }
    </section>
  );
}
