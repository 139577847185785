import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserForgetPasswordMutation } from "../services/authsApi";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    email: "",
  });

  const { email } = formValue;

  const [
    userForgetPassword,
    {
      data: forgetPasswordData,
      isSuccess: isForgetPasswordSuccess,
      isError: isForgetPasswordError,
      error: isForgetError,
    },
  ] = useUserForgetPasswordMutation();

  const handleChange = (e) => {
    setFormValue((formValue) => ({
      ...formValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (!formValue) {
      toast.isForgetError("Email does not exist");
    } else {
      const userData = {
        email,
      };
      await userForgetPassword(userData);
    }
  };

  useEffect(() => {
    if (isForgetPasswordSuccess) {
      toast.success(`Please, check your ${email} for reset link`);
      navigate("/login");
    }
  }, [isForgetPasswordSuccess]);

  return (
    <div
      className="hero min-h-screen bg-bg-off-white"
      style={{ color: "var(--color-black)" }}
    >
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:p-10 lg:text-left">
          <h1
            className="text-5xl font-bold"
            style={{ color: "var(--color-purple)" }}
          >
            Forgot Password
          </h1>
          <p className="py-6">
            Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
            excepturi exercitationem quasi. In deleniti eaque aut repudiandae et
            a id nisi.
          </p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl">
          <div className="p-2 shadow-xl">
            <h1>
              <p className="pb-2 pt-2">Please enter your email </p>
            </h1>
            <form className="form-div" onSubmit={handleForgetPassword}>
              <div className="">
                <label className="input-group pb-2">
                  <span className="w-1/4 pl-2 bg-purple-300 ">Email</span>
                  <input
                    type="email"
                    placeholder="info@top-soap.com"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <button
                className="btn btn-active bg-purple-900 hover:bg-purple-300 hover:text-black"
                type="submit"
              >
                Submit
              </button>
            </form>
            <span className="pr-[100px] btn-link">
              {" "}
              <Link to={"/register"}> Register </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
