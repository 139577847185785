import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { addToCart } from "../features/cartSlice";

import products from "../services/product";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };

  return (
    <div className="hero bg-bg-off-white">
      <div className="flex-col">
        <h1 className="text-black text-3xl text-center py-10">
          Register, Buy product, Refer and Earn Bonus with Exciting gifts Offers
          ...
        </h1>

        <div className="hero-content pt-5 pb-20 flex-col lg:flex-row">
          {products?.map((product) => (
            <div key={product.id.toString()} className="">
              <img
                src={product.image}
                width="300px"
                alt="home"
                className="max-w-sm shadow-3xl"
              />
              <div className="flex justify-between pt-3 px-3 bg-orange-300">
                <span className="text-gray-700 font-bold">
                  {" "}
                  Price: {product.price}
                </span>
                <span
                  className="text-white text-lg bg-purple-900 hover:bg-bg-l-purple hover:text-black rounded-md p-1 mb-2 "
                  onClick={() => handleAddToCart(product)}
                >
                  add to cart
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
