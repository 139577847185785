import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"


export const authsApi = createApi({
    reducerPath: "authsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: (body) => {
                return {
                    url: "/auth/login",
                    method: "post",
                    body,
                    
                };
            },
        }),
        
        userRegister: builder.mutation({
            query: (body) => {
                return {
                    url: "/auth/register",
                    method: "post",
                    body,                
                }
            }
        }),
        userForgetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: "/auth/reset-password",
                    method: "post",
                    body,
                }
            }
        }),

    }),
})

export const {useUserLoginMutation, useUserRegisterMutation, useUserForgetPasswordMutation, } = authsApi