import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import {
  useUserRegisterMutation,
} from "../services/authsApi";
import { useDispatch } from "react-redux";
import { logout, setUser } from "../features/authSlice";
import { MoonLoader } from "react-spinners";

export default function Auth() {
  const referrer = window.location.search;
  const referCode = new URLSearchParams(referrer).get("ref");

  const { ref } = useParams();

  const [formValue, setFormValue] = useState({
    fullname: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    cPassword: "",
    referral: ref || "",
  });

  const [
    userRegister,
    {
      data: registerData,
      isLoading: isRegLoading,
      isSuccess: isRegisterSuccess,
      isError: isRegisterError,
      error: isRegError,
    },
  ] = useUserRegisterMutation();

  const dispatch = useDispatch();

  const {
    fullname,
    username,
    email,
    phone_number,
    password,
    cPassword,
    referral,
  } = formValue;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormValue((formValue) => ({
      ...formValue,
      [e.target.name]: e.target.value,
    }));
  };


  const handleLogout = () => {
    dispatch(logout());
    toast.success("");
    navigate("/login");
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (formValue) {
      if (password !== cPassword) {
        toast.isRegError("Password does not match", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const userData = {
          fullname,
          username,
          email,
          phone_number,
          password,
          referral,
        };
        await userRegister(userData);
      }
    }
  };

  useEffect(() => {
    if (isRegisterSuccess) {
      toast.success(
        `Welcome ${fullname}, your user account has been created successfully`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      dispatch(
        setUser({
          fullname: registerData.data.fullname,
          email: registerData.data.email,
          username: registerData.data.username,
          phone_number: registerData.data.phone_number,
          loginToken: registerData.data.token,
          referral: registerData.data.ref,
        })
      );
      handleLogout()
      navigate("/login");
    }
  }, [isRegisterSuccess, isRegLoading, ref, dispatch]);

  return (
    <section
      className="hero min-h-screen bg-bg-off-white"
      style={{ color: "var(--color-black)" }}
    >
      {
isRegLoading ? <MoonLoader color="#36d7b7" /> :
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:p-10 lg:text-left">
          <h1
            className="text-5xl font-bold"
            style={{ color: "var(--color-purple)" }}
          >
            <>"Register Now"</>
          </h1>
          <p className="py-6">
          Register, Buy product, Refer and Earn Bonus with Exciting gifts Offers ...
          </p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm ">
          <div className="p-2 shadow-xl">
            <h1>
              <p className="pb-2"> "Please enter Register details"
              </p>
            </h1>
            <form
              className="form-div"
              onSubmit={ handleRegister}
            >
              <div className="">
                    <label className="input-group pb-2">
                      <span className="w-1/4 bg-purple-300 pl-2">Fullname</span>
                      <input
                        type="text"
                        placeholder="Please, enter your name"
                        className="w-3/4 input input-bordered bg-purple-200"
                        name="fullname"
                        value={fullname}
                        onChange={handleChange}
                      />
                    </label>
                    <label className="input-group pb-2">
                      <span className="w-1/4 bg-purple-300 pl-2">Phone</span>
                      <input
                        type="text"
                        placeholder="Please, enter your phone nuber"
                        className="w-3/4 input input-bordered bg-purple-200"
                        name="phone_number"
                        value={phone_number}
                        onChange={handleChange}
                      />
                    </label>
                <label className="input-group pb-2">
                  <span className="w-1/4 pl-2 bg-purple-300 ">Email</span>
                  <input
                    type="email"
                    placeholder="info@titaa.net"
                    className="w-3/4 input input-bordered bg-purple-200"
                    required
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </label>
                <label className="input-group pb-2">
                  <span className="w-1/4 pl-2 bg-purple-300">Pasword</span>
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                </label>
                    <label className="input-group pb-2">
                      <span className="w-1/4 pl-2 bg-purple-300">
                        Confirm Pasword
                      </span>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        className="w-3/4 input input-bordered bg-purple-200"
                        name="cPassword"
                        value={cPassword}
                        onChange={handleChange}
                      />
                    </label>
                    <label className="input-group pb-2">
                      <span className="w-1/4 bg-purple-300 pl-2">Username</span>
                      <input
                        type="text"
                        placeholder="Add a nick name"
                        className="w-3/4 input input-bordered bg-purple-200"
                        name="username"
                        value={username}
                        onChange={handleChange}
                      />
                    </label>
                    <label className="input-group pb-2">
                      <span className="w-1/4 bg-purple-300 pl-2">
                        Referral Code
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Referral Code"
                        className="w-3/4 input input-bordered bg-purple-200"
                        name="referral"
                        value={ref}
                        onChange={handleChange}
                      />
                    </label>
              </div>
                  <button
                    className="btn btn-active bg-purple-900 hover:bg-purple-300 hover:text-black"
                    type="submit"
                  >
                    Register
                  </button>
              <div className="">
                <h5> Already have an account with titaa Soap
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                      <span className="pr-[90px] md:pr-[120px]">
                              {" "}
                              <Link> Login </Link>
                            </span>
                      </button>
                </h5>
              </div>
            </form>
          </div>
        </div>
      </div>
}
    </section>
  );
}
