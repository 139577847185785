import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSetUpSystemMutation } from "../services/adminApi";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    frontend_base_url: "",
    phone_number: "",
    address: "",
  });

  const { frontend_base_url, phone_number, address } = formValue;

  const [
    setUpSystem,
    {
      data: adminData,
      isSuccess: isAdminSuccess,
      isError: isAdminError,
      error: adminError,
    },
  ] = useSetUpSystemMutation();

  const handleChange = (e) => {
    setFormValue((formValue) => ({
      ...formValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (!formValue) {
      toast.isForgetError("Email does not exist");
    } else {
      const userData = {
        frontend_base_url,
        phone_number,
        address,
      };
      console.log(userData);
      await setUpSystem(userData);
    }
  };

  useEffect(() => {
    if (isAdminSuccess) {
      toast.success(`Site setup completed successfully`);
      navigate("/dashboard");
    }
  }, [isAdminSuccess]);

  return (
    <div
      className="hero min-h-screen bg-bg-off-white"
      style={{ color: "var(--color-black)" }}
    >
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:p-10 lg:text-left">
          <h1
            className="text-5xl font-bold"
            style={{ color: "var(--color-purple)" }}
          >
            Amin Panel
          </h1>
          <p className="py-6">
            Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
            excepturi exercitationem quasi. In deleniti eaque aut repudiandae et
            a id nisi.
          </p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl">
          <div className="p-2 shadow-xl">
            <h1>
              <p className="pb-2 pt-2">Please enter your email </p>
            </h1>
            <form className="form-div" onSubmit={handleForgetPassword}>
              <div className="">
                <label className="input-group pb-2">
                  <span className="w-1/4 bg-purple-300 pl-2">Phone Number</span>
                  <input
                    type="text"
                    placeholder="Olufemi David"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="phone_number"
                    value={phone_number}
                    onChange={handleChange}
                  />
                </label>
                <label className="input-group pb-2">
                  <span className="w-1/4 bg-purple-300 pl-2">Base Url</span>
                  <input
                    type="text"
                    placeholder="Olufemi David"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="frontend_base_url"
                    value={frontend_base_url}
                    onChange={handleChange}
                  />
                </label>
                <label className="input-group pb-2">
                  <span className="w-1/4 bg-purple-300 pl-2">Site Adress</span>
                  <input
                    type="text"
                    placeholder="Olufemi David"
                    className="w-3/4 input input-bordered bg-purple-200"
                    name="address"
                    value={address}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <button
                className="btn btn-active bg-purple-900 hover:bg-purple-300 hover:text-black"
                type="submit"
              >
                Sutup Site
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
