import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const loggedInUserApi = createApi({
  reducerPath: "loggedInUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      let user = JSON.parse(localStorage.getItem("user"));
if (user.loginToken) {
  headers.set('Authorization', `Bearer ${user.loginToken}`);
}      
return headers;
},
  }),
  endpoints: (builder) => ({
    loggedInUser: builder.query({
      query: () => {
        return {
          url: "/auth/loggedin/user",
          method: "get",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
  }),
});

export const { useLoggedInUserQuery } = loggedInUserApi;
