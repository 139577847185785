import { createSlice, } from "@reduxjs/toolkit";


const user = JSON.parse(localStorage.getItem("user"))

const initialState = {
  user: user ? user : null,
  message: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(
        "user",
        JSON.stringify(action.payload)
      );
      state.user = action.payload;
      state.user.userToken = action.payload.loginToken;
    },

    logout: (state) => {
      localStorage.clear();
      state.phone_number = ""
      state.frontend_base_url = ""
      state.address = ""
    }
  },
});

export const selectAuth = (state) => state.auth;
export const { setUser, logout } = adminSlice.actions;
export default adminSlice.reducer;
