import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../features/authSlice'
import adminReducer from '../features/adminSlice'
import cartReducer from '../features/cartSlice'
import loggedInReducer from '../features/loggedInSlice'
import paymentReducer from '../features/paymentSlice'
import {authsApi} from '../services/authsApi'
import {paymentApi} from '../services/paymentApi'
import {setupListeners} from "@reduxjs/toolkit/query/react"
import { adminApi } from "../services/adminApi";
import {loggedInUserApi} from "../services/loggedInUserApi"



const store = configureStore({
    reducer: {
      auth: authReducer,
      [authsApi.reducerPath]: authsApi.reducer,
      cart: cartReducer,
      payment: paymentReducer,
      [paymentApi.reducerPath]: paymentApi.reducer,
      admin: adminReducer,
      [adminApi.reducerPath]: adminApi.reducer,
      loggedInApi: loggedInReducer,
      [loggedInUserApi.reducerPath]: loggedInUserApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([authsApi.middleware, paymentApi.middleware, adminApi.middleware, loggedInUserApi.middleware ]),
  });
  setupListeners(store.dispatch)

  export default store;