import React, { useEffect,  } from 'react'
import { Link, } from 'react-router-dom'
// import { useGetPaymentMutation } from '../services/paymentApi'
import { useInitialPaymentMutation, } from "../services/paymentApi"


export default function RegSuccessPage() {
  const [initialPayment, {data: initPaymentData, isSuccess: isInitPaymentSuccess, isInitError: isInitPaymentError, error: isInitPayError}] = useInitialPaymentMutation()

  const user = JSON.parse(localStorage.getItem("user"))
  
  const amount = 1500

  const params = window.location.search;
  const status = new URLSearchParams(params).get("status")
  const transaction_id = new URLSearchParams(params).get("transaction_id")

// console.log(response);
    useEffect(() => {
  
      if (status === 'completed') {
        try {
          async function paymentRedirect(){
            await initialPayment({transaction_id});
            // await initialPayment({transaction_id: JSON.stringify(transaction_id)});
              // transaction_id: JSON.stringify(transaction_id)
        }
        paymentRedirect()
        } catch (error) {
          console.log(error);
        }
      }
    }, [params]);
  return (
    <div >
      <h1>Your payment has been successfully processed</h1>
      <p>Your ticket No. is: {transaction_id} </p>
      <Link to={'/dashboard'}>Back to dashboard</Link>
    </div>
  )
}
