import { createSlice } from "@reduxjs/toolkit";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },

    logout: (state) => {
      localStorage.clear();
    },
  },
});

export const selectAuth = (state) => state.auth;
export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
