import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout, } from "../features/authSlice";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { CgMoreR } from "react-icons/cg";
import { TbNotification } from "react-icons/tb";
import { CgDetailsMore } from "react-icons/cg";
import { useLoggedInUserQuery } from "../services/loggedInUserApi";
import { FiMenu, FiX } from 'react-icons/fi'


export default function Dasboard() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  const menuItems = [
    { id: 1, name: 'Dashboard', icon:<MdOutlineDashboardCustomize/>, link: '/dashboard',},
    { id: 2, name: 'Notifications', icon:<TbNotification/>, link: '/notifications' },
    { id: 2, name: 'Downlines', icon:<AiOutlineUsergroupAdd/>, link: '/downlines' },
    { id: 2, name: 'Buy Product', link: '/' },
  ];

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const { data } = useLoggedInUserQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientBaseUrl = "https://titaa.net";
  const referralCode = data?.data.referralCode;

  const handleLogout = () => {
    dispatch(logout());
    toast.success(`You have logged out successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/login");
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
 
  return (

    <div className="flex flex-col md:flex-row h-screen">
      {/* Mobile Menu Toggle Button */}
      <button
        className="md:hidden bg-purple-700 text-white p-2"
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />} {/* Use FiX or FiMenu icon based on menu state */}
      </button>

      {/* Sidebar */}
      <div className={`bg-purple-700 text-white w-full md:block md:w-64 ${!isMobileMenuOpen? "hidden" : "block"}`}>
        <ul className="p-2">
          { menuItems.map((item) => (
            <li key={item.id} className="px-4 py-2 hover:bg-gray-600">
              <a href={item.link} onClick={toggleMobileMenu}><span className="flex items-center gap-3">{item.icon}{item.name}</span>
              </a>
            </li>
          ))}
          </ul>
<button onClick={handleLogout} className="relative bottom-100 left-5 mb-5 mt-10 p-2 rounded-md bg-gray-900 hover:text-red-500">Logout</button>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-purple-200 text-black">
        {
          menuItems.map((item) => (
            item.name === 'Dashboard' ? <>
            <div className="text-black flex pl-10 pt-4 md:pt-5">
          {!data?.data.isMembership ? (
            <button className="p-1 ml-10  ">
              <Link to={"/registration-payment"}> Pay Registration Fee to Continue</Link>
            </button>
          ) : (
            <div className="">
              <h1 className="text-2xl py-3 shadow-xl w-auto">Welcome {data?.data.fullname}</h1>
              <div className="py-5 ">
                <div className="md:flex md:justify-between items-center gap-10">
              <div className="text-lg font-normal py-2 "> username: {data?.data.username}</div>
              <div className="text-lg block">
                {" "}
                referral Link:{" "}
                <button onClick={()=>{window.open(`${clientBaseUrl}/register/${referralCode}`, '_self')}} className="text-lg">
                {`${clientBaseUrl}/register/${referralCode}`}
                </button>
              </div>
                </div>
              </div>
              <div>
              <div className=""> your referralTrack: {data?.data.referralTrack}</div>
              <div className="w-auto py-2 font-normal"> your Level: {data?.data.member}</div>
              <div className="w-auto py-2 font-normal"> your cumulative: {data?.data.cumulative}</div>
              <div className="w-auto py-2 font-normal"> your wallet: {data?.data.wallet}</div>
              <div></div>
              </div>
            </div>
            )} 
        </div>
            </>:item.component
          ))
        }
      </div>
    </div>
  )}
