import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.loginToken) {
        headers.set("Authorization", `Bearer ${user.loginToken}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    initialPayment: builder.mutation({
      query: (body) => {
        return {
          url: "/initial-transaction",
          method: "post",
          body,
        };
      },
    }),

    userPayment: builder.mutation({
      query: (body) => {
        return {
          url: "/product-transaction",
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const { useInitialPaymentMutation, useUserPaymentMutation } = paymentApi;
