import dishwash from "../images/dishwash.jpeg"
// import autowash from "../images/autowash.jpeg"
import fabricwash from "../images/fabricwash.jpeg"

const products = [
    {
        id: 1,
        name: 'Dish Wash',
        desc: 'Sparkling clean washer',
        price: 10000,
        image: dishwash
    },
    {
        id: 2,
        name: 'Fabric Wash',
        desc: 'For all kind of fabrics',
        price: 10500,
        image: fabricwash
    },
    // {
    //     id: 3,
    //     name: 'Auto Wash',
    //     desc: 'Paint friendly automobile wash',
    //     price: 11000,
    //     image: autowash
    // },
]

export default products;