import React from "react";
import { useNavigate } from "react-router-dom";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";

export default function RegPayment() {
  const navigate = useNavigate();
 
  const user = JSON.parse(localStorage.getItem("user"));

  const amount = 1500;

  const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    payment_options: "card,banktransfer,ussd",
    customer: {
      email: user.email,
      phone_number: user.phone_number,
      username: user.username,
      name: user.fullname,
    },
    customizations: {
      title: "Titaa Multipurpose Soap",
      description: "Membership Registration Payment",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
    redirect_url: "https://titaa.net/reg-payment-success",
  };
  const fwConfig = {
    ...config,
    text: "Pay Now!",
    callback: async () => {
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {
      navigate('/')
    },
  };

  return (
    <section className="bg-white mx-auto h-screen">
      <div className="flex justify-center p-20">
        <div className="text-center ">
          <h1>Registration Payment</h1>
          <FlutterWaveButton
            className="bg-orange-400 text-black text-bold p-2 "
            {...fwConfig}
          />
        </div>
      </div>
    </section>
  );
}
