import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"


export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL
    }),
    endpoints: (builder) => ({
        setUpSystem: builder.mutation({
            query: (body) => {
                return {
                    url: "/set-up-system",
                    method: "post", 
                    body,
                    
                };
            },
        }),
    }),
})

export const { useSetUpSystemMutation} = adminApi