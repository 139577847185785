import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dasboard from "./pages/Dasboard";
import { useSelector } from "react-redux";
import RegPayment from "./pages/RegPayment";
import Btn from "./components/Btn";
import logo from "./images/logo.jpeg";
import { HiOutlineShoppingCart } from "react-icons/hi";
import Cart from "./pages/cart";
import Admin from "./pages/Admin";
import RegSuccessPage from "./pages/RegSuccessPage";
import ProductPaymentSuccessPage from "./pages/ProductPaymentSuccessPage";

function App() {
  const { cartTotalQuantity } = useSelector((state) => state.cart);

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <BrowserRouter>
        <div className="navbar bg-purple-900 md:px-20">
          <div className="navbar-start">
            <Link to="/">
              <span className=" bg-white text-xl ">
                <div className="flex">
                  <img width="60px" height="40px" src={logo} alt="logo" className="rounded-md" />
                  <h5 className="hidden md:block md:p-3 ">
                    Multi-Purpose
                  </h5>
                </div>
              </span>
            </Link>
          </div>
          <div className="navbar-end text-white">
            <div className="mr-5 relative">
              <HiOutlineShoppingCart className="text-3xl w-full text-orange-300" />
              <span className="bg-red-600 px-1 mt-1 text-white rounded-full absolute top-[-20px] right-[-10px]">
                {cartTotalQuantity}
              </span>
            </div>
            {user ? (
              <Link to={"/dashboard"}>Dasboard</Link>
            ) : (
              <Btn>
                <Link to="/login">Login</Link>
              </Btn>
            )}
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/:ref" element={<Register />} />
          <Route path="/admin-panel" element={<Admin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/dashboard" element={<Dasboard />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/product-payment" element={<Payment />} />
          <Route path="/registration-payment" element={<RegPayment />} />
          <Route path="/reg-payment-success" element={<RegSuccessPage />} />
          <Route path="/product-payment-success" element={<ProductPaymentSuccessPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
