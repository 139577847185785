import { createSlice } from "@reduxjs/toolkit";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  message: null,
};

const loggedInSlice = createSlice({
  name: "loggedIn",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(
        "user",
        JSON.stringify(action.payload)
        // JSON.stringify(action.payload.map(user))
      );
      state.user = action.data.payload;
      state.user.userToken = action.payload.loginToken;
    },
  },
});

export const selectAuth = (state) => state.loggedIn;
export const { setUser } = loggedInSlice.actions;
export default loggedInSlice.reducer;
