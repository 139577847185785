import { createSlice, } from "@reduxjs/toolkit";


const user = JSON.parse(localStorage.getItem("user"))

const initialState = {
  user: user ? user : null,
  message: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {

    initialPayment: (state, action) => {
        // state.fullname = action.payload.fullname;

        localStorage.setItem(
          "user",
          JSON.stringify(action.payload)
        );
        state.user = action.payload;
        state.user.userToken = action.payload.loginToken;
    },

  },
});

export const selectPayment = (state) => state.payment;
export const { initialPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
